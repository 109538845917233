import PageHeader from '../components/common/PageHeader'
import AboutFestivals from '../components/festivals/AboutFestivals'
import FestivalsCardWrap from '../components/festivals/FestivalsCardWrap.jsx'
import GoToTop from '../helpers/GoToTop'
import MetaData from '../helpers/MetaData'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useEffect, useState } from 'react'
import ApiService from '../api/ApiService'

const Festivals = () => {


  const [meta, setMeta] = useState(null)
    const fetchMeta = async (pagename) => {
        try {
            let res = await ApiService.fetchMetaData(pagename)
            setMeta(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchMeta('festivals')
    }, [])

  return (
    <>
      <MetaData title="Festivals - ISKCON of Bhiwandi" />
        <PageHeader title="Festivals" imgSrc="/images/page-header/22.webp" />
        <AboutFestivals />
        <FestivalsCardWrap />
        <section className="unable-to-make-it">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="heading">
                  <h2 className="head-1 text-uppercase text-center">
                    unable to make it
                  </h2>
                </div>
                <div className="dropdown-wrap mt-4">
                  <DropdownButton title="WATCH HERE">
                    <Dropdown.Item href="https://www.mayapur.tv/" target="_blank">Mayapur TV</Dropdown.Item>
                    <Dropdown.Item href="https://www.instagram.com/iskconbhiwandi/" target="_blank"> Instagram </Dropdown.Item>
                    <Dropdown.Item href="https://www.youtube.com/iskconbhiwandi" target="_blank"> Youtube </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GoToTop />
    </>
  )
}

export default Festivals