import React from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Fancybox from "../helpers/Fancybox";
import { Link } from "react-router-dom";

const ShopList = () => {
  return (
    <>
      <MetaData title="Shop - ISKCON of Bhiwandi" />
      <PageHeader title="SHOP" imgSrc="/images/page-header/24.jpg" />
      <section className="shop-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">
                  Premium Sandalwood Agarbatti
                </h2>
                <p>
                  Made from sandalwood powder and completely charcoal-free,
                  these hand-rolled agarbatti sticks have a calming effect on
                  the body and mind. This premium sandalwood agarbatti is ideal
                  for pooja, meditation and yoga. The fragrance of sandalwood is
                  known to enhance meditation, worship. and invokes positivity
                  in one's living space.
                </p>
                <p className="mt-4 highlighted-text">
                  Contains: 15 premium sticks (approx 40 grams)
                </p>
                <Link
                  to="/shop-checkout"
                  className="custom-btn-cls box-hover ms-0 px-4 py-2"
                >
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Premium Sandalwood Agarbatti</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/agrbatti-1.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/agrbatti-1.png" alt="" />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/agrbatti-2.webp"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/agrbatti-2.webp" alt="" />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/agrbatti-3.webp"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/agrbatti-3.webp" alt="" />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/agrbatti-4.webp"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/agrbatti-4.webp" alt="" />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">
                  Premium Khus Agarbatti
                </h2>
                <p>
                  Made of khus powder and completely charcoal free our hand-rolled agarbatti stick have a calming effect on your body and mind. This premium khus agarbatti fragrance combines traditional yet classic ingredients like amber, hina, rose, and musk to create a floral fresh green musky accord that lingers with warm sweet ambery notes.
                </p>
                <p className="mt-4 highlighted-text">
                  Contains: 18 premium stick ( Approx 40 grams)
                </p>
                <Link
                  to="/shop-checkout/1"
                  className="custom-btn-cls box-hover ms-0 px-4 py-2"
                >
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Premium Khus Agarbatti</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/001 Agarbatti Packaging June_2023_001.jpg"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/001 Agarbatti Packaging June_2023_001.jpg" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/001 Agarbatti Packaging June_2023_002.jpg"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/001 Agarbatti Packaging June_2023_002.jpg" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/001 Agarbatti Packaging June_2023_003.jpg"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/001 Agarbatti Packaging June_2023_003.jpg" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/001 Agarbatti Packaging June_2023_004.jpg"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/001 Agarbatti Packaging June_2023_004.jpg" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">
                Oudh Bambooless Incense Sticks
                </h2>
                <p>
                Made with 100% plant derived ingredients and free from bamboo, these incense sticks emit a soothing fragrance that purifies the environment and elevates your sprit.  
                </p>
                <p className="mt-4 highlighted-text">
                  Contains: 27 Incense Sticks in box (Approx. 100 grams)
                </p>
                <Link
                  to="/shop-checkout/4"
                  className="custom-btn-cls box-hover ms-0 px-4 py-2"
                >
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Oudh Bambooless Incense Sticks</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/Front Bhiwandi.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/Front Bhiwandi.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/Back Bhiwandi.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/Back Bhiwandi.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/Front Bhiwandi.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/Front Bhiwandi.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/Back Bhiwandi.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/Back Bhiwandi.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">
                Ayodhya Ram Mandir Replica
                </h2>
                <p>
                The 3D Wooden Model of Ram Janmabhoomi Temple in Ayodhya is a symbol of devotion to Lord Ram. Crafted with eco-friendly materials and intricate detail, it's perfect for car dashboards, home temples, or study tables. As the Ram Mandir takes shape in Ayodhya, this model honors the sacred site of Lord Ram's birthplace.
                </p>
                <p className="mt-4 highlighted-text">
              
                </p>
                <Link
                  to="/shop-checkout/3"
                  className="custom-btn-cls box-hover ms-0 px-4 py-2"
                >
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Ram Mandir</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/ram-01.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/ram-01.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/ram-02.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/ram-02.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/ram-03.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/ram-03.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/ram-04.png"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/ram-04.png" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>

        {/* New Book Section Start */}

        {/* New Book Section End */}
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-0 mt-3 mt-lg-0">
              <div className="heading text-start">
                <h2 className="head-1 d-none d-lg-block">
                  Bhaktivedanta Book Store
                </h2>
                <p>
                  Prabhupada’s books are store houses of light and here a select reading list has been made available to buy online.
                </p>

                <Link
                  to="https://iskcontsp.org/spiritual-shop/"
                  className="custom-btn-cls box-hover ms-0 px-4 py-2"
                >
                  shop now
                </Link>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="heading text-start mb-4 d-lg-none">
                <h2 className="head-1">Premium Khus Agarbatti</h2>
              </div>
              <div className="shop-slider-wrap">
                <Splide
                  options={{
                    type: "loop",
                    rewind: false,
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    speed: 1000,
                    interval: 2000,
                    pauseOnHover: true,
                  }}
                >
                  <SplideSlide>
                    <Fancybox>
                      <a
                        href="/images/shop/bhaktivendanta.webp"
                        data-fancybox="images"
                      >
                        <figure className="mb-0">
                          <img src="/images/shop/bhaktivendanta.webp" alt="" style={{ filter: "drop-shadow(0px 0px 0px)" }} />
                        </figure>
                      </a>
                    </Fancybox>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopList;
