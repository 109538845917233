import React from 'react';
import { useLocation } from 'react-router-dom';

const YatraThankYouPage = () => {
    const location = useLocation();
   // const yatraTitle = location.state && location.state.yatraTitle;
    const { yatraTitle, pay } = location.state || {};
    return (
        pay === 'complete' ? 

            <div style={{ marginTop: "250px" }}>
            <div className="thank-you-message text-center">
                <h2>Thank you for the payment. {yatraTitle}.</h2>
                <p style={{fontSize:'24px'}}>We will get back to you soon on further details of the yatra and the literary.</p>
               
                <p style={{fontSize:'24px'}}>In case you have any queries you can reach out to our volunteers at <a href="tel:+9175263178">91752 63178</a>.</p>
            </div>
        </div>

        :        
          <div style={{ marginTop: "250px" }}>
            <div className="thank-you-message text-center">
                <h2>Thank you for your registration for the {yatraTitle}.</h2>
                <p style={{fontSize:'24px'}}>We will share further details with you soon and we will get back to you regarding confirmation of rooms.</p>
               
                <p style={{fontSize:'24px'}}>In case you have any queries you can reach out to our volunteers at <a href="tel:+9175263178">91752 63178</a>.</p>
            </div>
        </div>
        
    );
};

export default YatraThankYouPage;
